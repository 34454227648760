import React from "react"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Banner from "../components/banner"
import {  graphql, Link } from "gatsby"
import Seo from "../components/seo"
const banner_title = ['Happiness', 3000, 'Satisfaction', 2000,'& Business Growth' , 1000]; 


const case_study = ({data}) => {
    
  const case_studiesArr = data.allWpCaseStudy.edges;
 
    function chunkArray(array, size) {
        
      if(array.length <= size){
         
        return [array]
        
      }
      
      return [array.slice(0,size), ...chunkArray(array.slice(size), size)]
     
      }


     const spilitdataArr = chunkArray(case_studiesArr,2 );


return (

<Layout>
<Seo title= "Checkout the success story of our happy Clients. " />
<Banner className = "service" title = {banner_title} sticky_first = "We’re passionate " sticky_second  = "about our clients" />

<section className = "case_frid">


    <div className = "container">

     

        {spilitdataArr.map(function (singleData) {
           // console.log('singleData', singleData)
            
            return (
<div className = "row_grid">

                {singleData.map(function (item) {
                    return (

                     

                        <div className = "case">
                        <h1>{item.node.title}</h1>
                        <div className = "case_ser">
            
                       <Link to ={`/case_studies/${item.node.slug}`}>
            
                 <GatsbyImage
                  image= {getImage(item.node.caseStudy.featuredImage.localFile)}
                  quality = "90"
                 
                  alt="A Gatsby astronaut"
                  placeholder="blurred"
                />
               
               
               
               
               </Link>
               <h3><i className="fa fa-arrow-right" aria-hidden="true"></i> {item.node.caseStudy.projectType}</h3>
            
            
                        </div>
                    
                    
                    
                    
                    
                    </div>
            




                    );
                })}
</div>
                );


            })}

    </div>

</section>

</Layout>




)


}



export const case_studies = graphql` 
query allCaseStudies {
    allWpCaseStudy  (sort: {fields: date, order: DESC}){
      edges {
        node {
          slug
          title
          caseStudy {
            featuredImage {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
                }
              }
            }
            projectType
          }
        }
      }
    }
  }`

  
export default case_study;